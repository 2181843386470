.SpeakerTileContainer {
  width: 25%;
  position: relative;
  background-color: black;
  display: inline-block;
}

@media (max-width: 768px) {
  .SpeakerTileContainer {
    width: 100%;
  }
}

.year {
  position: absolute;
  float: left;
  top: 0;
  display: block;
  background-color: black;
  padding: 6px;
  text-decoration: none;
  color: white;
}

.year:hover {
  background-color: aqua;
}

.image {
  vertical-align: bottom;
  opacity: 93%;
  transition-duration: 600ms;
}

.image:hover {
  opacity: 100%;
  transition-duration: 600ms;
}

.name {
  position: absolute;
  bottom: 0px;
  background-color: black;
  text-align: center;
  width: 100%;
  font-size: 18px;
}

.nameText {
  text-decoration: none;
  margin: 6px 0px;
  color: white;
}
.ListRoot {
  text-align: center;
  margin-top: 20px;
}

.ListTitleContainer {
  display: block;
  background-color: black;
  color: white;
  font-size: 30px;
  padding: 10px;
}

.List {
  margin-top: 50px;
  width: 100%;
  text-align: center;
}

.Block {
  position: relative;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
  display: inline-block;
  width: 25%;
  margin-right: 10px;
  margin-left: 10px;
  color: black;

  text-decoration: none;
  margin-bottom: 10px;
}

.BlockThumbnail {
  width: 100%;
  display: block;
}

.BlockTitle {
  margin-top: 8px;
  overflow-wrap: break-word;
  padding-bottom: 14px;
}

.UpdatedDate {
  right: 0px;
  bottom: 0px;
  font-size: 10px;
  position: absolute;
}

@media (max-width: 500px) {
  .Block {
    width: 90%;
    margin-right: 0px;
    margin-left: 0px;
  }
}
.NoSpeakerFound {
    text-align: center;
    padding-top: 20px;
}
.ReturnToSpeakerListButton{
    margin-left:5%;
    color:gray;

    font-size: large;
}
.ReturnToSpeakerListButton:hover{
    cursor: pointer;
}
.ProfileTitle {
    text-align: left;
    padding-left: 5%;
}

.ProfileImage {
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.5);
    margin-left: 5%;
    width: 30%;
    height: auto;
    display: inline-block;
}

.Profiledescription {
    padding-left: 5%;
    width: 50%;
    vertical-align: top;
    height: auto;
    display: inline-block;
}

.ProfileslideShowSubTitle {
    padding-left: 5%;
    margin-bottom: 0px
}

.YoutubeLink {
    vertical-align: bottom;
}

@media (max-width: 700px) {
    .ProfileImage {
        margin-left: 5%;
        width: 90%;
        height: auto;
        display: inline-block;
    }

    .Profiledescription {
        padding-top: 10px;
        padding-left: 5%;
        width: 90%;
        vertical-align: top;
        height: auto;
        display: inline-block;
    }

}
.top {
  padding-top: 72px;
  width: 100%;
  height: auto;
  background-color: black;
}
.background-image {
  position: relative;
  width: 100%;
  height: auto;
}
.toTop {
  display: block;
  text-decoration: none;
  color: white;
  background-color: gray;
  text-align: center;
  width: 100%;
  padding: 10px 0px;
  font-weight: 800;
}
@media (max-width: 490px) {
  .top {
    padding-top: 59px;
  }
}

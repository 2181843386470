.HamburgerMenu {
  text-align: left;
  right: 50px;
  display: inline-block;
  vertical-align: top;
  padding: 12px 0px;
  color: white;
  text-decoration: none;
  position: absolute;
}
.hamburger-img {
  height: 46px;
}

@media (max-width: 700px) {
  .hamburger-img {
    height: 39px;
  }
  .HamburgerMenu {
    right: 5px;
    padding: 10px 0px;
  }
}

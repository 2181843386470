.header {
  z-index: 1;
  position: fixed;
  text-align: center;
  width: 100%;
  background-color: black;
}

.header-logo-outter {
  color: black;
  display: inline-block;
  text-align: center;
}
.header-logo-img {
  width: 250px;
  margin: 0px;
}
.outter-sns-links {
  list-style: none;
  text-align: center;
  font-size: 50px;
}
.outter-sns-links li {
  display: inline-block;
  padding: 20px;
}
.outter-sns-links a {
  color: blue;
}
.footer-outter {
  background-color: black;
  display: block;
}
.footer-left-panel {
  display: inline-block;
  width: 48%;
}
.footer-logo-image {
  width: 70%;
}
.footer-right-panel {
  font-size: 19px;
  width: 50%;
  vertical-align: top;
  padding-top: 10px;
  text-align: right;
  display: inline-block;
}
.footer-right-item {
  padding-top: 40px;
  text-align: left;
  display: inline-block;
  padding-right: 80px;
  color: white;
  text-decoration: none;
}
.footer-copyright {
  padding-top: 10px;
  padding-left: 10px;
  color: white;
  text-align: center;
  display: inline-block;
}
@media (max-width: 700px) {
  .outter-sns-links {
    font-size: 25px;
    padding-inline-start: 0px;
  }
  .outter-sns-links li {
    padding: 15px;
  }
  .header-logo-img {
    width: 200px;
  }
  .footer-left-panel {
    display: block;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .footer-logo-image {
    padding-top: 10px;
    padding-left: 5%;
    width: 95%;
  }
  .footer-right-panel {
    text-align: center;
    width: 100%;
    padding-top: 0px;
  }
  .footer-right-item {
    display: inline-block;
    padding-right: 5%;
    text-align: left;
    padding-left: 5%;
  }
}

.partners-title-outter {
  padding-top: 100px;
  font-size: 40px;
  padding-left: 5%;
}
.partners-title {
  color: #929699;
}
.partners-contents-outter {
  text-align: center;
}
.partners-contents-inner {
  display: inline-block;
  text-align: center;
  padding-top: 50px;
  width: auto;
}
.partner-logo-img {
  display: inline-block;
  width: 100%;
}
.partner-link {
  margin: 75px 75px;
  vertical-align: top;
  display: inline-block;
  width: 300px;
}
.partner-link-big {
  margin: 75px 75px;
  vertical-align: top;
  display: inline-block;
  width: 600px;
  height: auto;
}
@media (max-width: 675px) {
  .partner-link {
    text-align: center;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 50%;
    width: 85%;
  }
  .partner-link-big {
    text-align: center;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 50%;
    width: 85%;
  }
  .partners-contents-inner {
    display: block;
    width: 100%;
    text-align: center;
  }
}

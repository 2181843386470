.menu {
  position: relative;
  text-decoration: none;
  margin: 14px 0px;
  display: block;
  color: white;
}
.inner {
  /*ドロップダウンメニュー内部の要素は階層構造を表すため、少し右側にパディングする*/
  padding-left: 14px;
}

.dropdownmenu-inner {
  display: inline-block;
  text-align: left;
  width: 40%;
  margin: 5px 0px;
  cursor: pointer;
}
.dropdownmenu-outter:hover {
  background-color: #00aac4;
}
.dropdownmenu-outter {
  text-align: center;
  display: block;
  line-height: 25px;
  white-space: break-spaces;
  font-size: 20px;
  color: white;
  margin: 9px 0px;
}

@media (max-width: 500px) {
  .menu-outter {
    font-size: 25px;
  }
}

.contact-title-outter {
    padding-top: 100px;
    font-size: 30px;
    padding-left: 5%;
  }
  .contact-title {
    color: #929699;
  }
  .contact-contents-outter {
    text-align: center;
  }
  .contact-contents-inner {
    display: inline-block;
    text-align: left;
    width: 90%;
  }
  
.TitleContaner{
    text-align: center;
    padding:100px 0px;
}
.TitleText{
    font-size: 38px;
    color: #929699;
}
.SubTitle{
    font-size: 15px;
    color: #929699;
}
.SessionTitle{
    text-align: center;
    font-size: 30px;
}